import * as _functionBind2 from "function-bind";
var _functionBind = _functionBind2;
try {
  if ("default" in _functionBind2) _functionBind = _functionBind2.default;
} catch (e) {}
import _functionApply from "./functionApply";
import _actualApply from "./actualApply";
var exports = {};
var bind = _functionBind;
var $apply = _functionApply;
var actualApply = _actualApply;

/** @type {import('./applyBind')} */
exports = function applyBind() {
  return actualApply(bind, $apply, arguments);
};
export default exports;